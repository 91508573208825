import BaseBlockContent from '@sanity/block-content-to-react';
import React from 'react';
import styled from 'styled-components';

import SanityImage from './SanityImage';

const client = require('@sanity/client')({
  projectId: '1xyfjvho',
  dataset: 'production',
  useCdn: true,
});

const TextContainer = styled.div`
  max-width: 1440;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  margin-bottom: 0.5rem;
`;

const BodyText = styled.div`
  /* border: 1px solid black; */
  margin: 0;
  padding: 0;
  width: 780px;
  max-width: 100%;
  // margin-bottom: 1rem;
  // margin-top: 1rem;
`;

const CaptionBorder = styled.div`
  /* border: 1px solid black; */
  margin-top: 0.25rem;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid black;
`;

function Smarten(a) {
  a = a.replace(/(^|[-\u2014\s(\["])'/g, '$1\u2018'); // opening singles
  a = a.replace(/'/g, '\u2019'); // closing singles & apostrophes
  a = a.replace(/(^|[-\u2014/\[(\u2018\s])"/g, '$1\u201c'); // opening doubles
  a = a.replace(/"/g, '\u201d'); // closing doubles
  a = a.replace(/--/g, '\u2014'); // em-dashes
  return a;
}

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return (
            <TextContainer>
              <BodyText>
                <h1>{props.children}</h1>
              </BodyText>
            </TextContainer>
          );
        case 'h2':
          return (
            <TextContainer>
              <BodyText>
                <h2>{props.children}</h2>
              </BodyText>
            </TextContainer>
          );
        case 'h3':
          return (
            <TextContainer>
              <BodyText>
                <h3>{props.children}</h3>
              </BodyText>
            </TextContainer>
          );
        case 'h4':
          return (
            <TextContainer>
              <BodyText>
                <h4>{props.children}</h4>
              </BodyText>
            </TextContainer>
          );

        case 'h6':
          return (
            <CaptionBorder>
              <h5>{props.children}</h5>
            </CaptionBorder>
          );

        // ...

        default:
          return (
            <TextContainer>
              <BodyText>
                <p className="serif">{props.children}</p>
              </BodyText>
            </TextContainer>
          );
      }
    },
    // image(props) {
    //   return (
    //     <>
    //       {/* <p>image</p> */}
    //       <SanityImage data={props} />
    //     </>
    //   );
    // },

    // captionimage(props) {
    //   return (
    //     <>
    //       <p>({JSON.stringify(props)})</p>
    //     </>
    //   );
    // },
  },
};

const BlockContent = ({ blocks }) => (
  <BaseBlockContent
    blocks={blocks}
    serializers={serializers}
    imageOptions={{ w: 1600, fit: 'scale' }}
    projectId="1xyfjvho"
    dataset="production"
  />
);

export default BlockContent;

// const serializers = { types: { captionimage: ({ node: { asset, caption } }) => { return <img src={asset} alt={caption} />; } } };
