import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import BlockContent from '../components/BlockContent';
import SEO from '../components/SEO';
import SingleTag from '../components/SingleTag';

const CaseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CaseContainerSafe = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const CaseTitleBlock = styled.div`
  max-width: 100%;
  /* width: 100%; */
  background-color: white;
  margin-bottom: 2rem;
  padding: 0.5rem;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 5px 5px 15px #d9d9d9;
  padding: 0.9rem;
  display: inline-block;
  /* padding-left: 1rem;
  
  margin-top: 1rem; */
`;

const TagContainer = styled.div`
  border: 1px solid black;
  border-radius: 1.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: inline-block;
  align-content: center;
  margin: 0.3rem;
`;

const FormatTags = (tags) => {
  const tagsArray = [];

  tags.map((tag) =>
    tagsArray.push(tag.name.charAt(0).toUpperCase() + tag.name.slice(1))
  );
  return tagsArray.join(' | ');
};

export default function SingleCaseStudy({ data }) {
  const CaseStudy = data.caseStudy;

  return (
    <>
      <SEO title={CaseStudy.name} />
      <CaseContainer>
        <CaseContainerSafe>
          <CaseTitleBlock>
            {/* <span className="eyebrow">CASE</span> */}
            <h2 className="caseName">{CaseStudy.name}</h2>
            <h3 className="description light">{CaseStudy.description}</h3>
            <p>
              <span className="eyebrow">AGENCY</span> {CaseStudy.agency}{' '}
            </p>
            <p>
              <span className="eyebrow"> DATE </span> {CaseStudy.displaydates}{' '}
            </p>
            <p>
              <span className="eyebrow"> ACTIVITIES</span>{' '}
              {/* {JSON.stringify(CaseStudy.tags)} */}
              {FormatTags(CaseStudy.tags)}
            </p>
          </CaseTitleBlock>
          <BlockContent blocks={CaseStudy._rawBody} />
        </CaseContainerSafe>
        {}
      </CaseContainer>
    </>
  );
}

// This needs to be dynamic based on the slug passed in via context in gatsby-node.js
export const query = graphql`
  query ($slug: String!) {
    caseStudy: sanityCasestudy(slug: { current: { eq: $slug } }) {
      name
      id
      date
      _rawBody
      agency
      description
      displaydates
      tags {
        name
      }
    }
  }
`;
